import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import Annotator from 'react-image-annotate';
import { useSnackbar } from 'notistack';
import { useSetEventNewDetection } from 'src/hooks/eventHooks';

const AnnotateImage = ({
  mzRef = React.createRef(),
  playerElRef,
  onDone,
  eventId,
  pts_seconds,
  regions,
}) => {
  // IMAGE -- captured from live video
  const [image, setImage] = React.useState(null);
  const b64Image = React.useRef(null);
  const capture = () => {
    if (!playerElRef.current) return;
    const canvas = document.createElement('CANVAS');
    const video = playerElRef.current;
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    canvas
      .getContext('2d')
      .drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
    canvas.toBlob((blob) => {
      let img = new Image();
      if (blob) {
        img = URL.createObjectURL(blob);
        setImage(img);

        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
          const base64data = reader.result.substr(
            reader.result.indexOf(',') + 1
          );
          b64Image.current = base64data;
        };
      }
    }, 'image/jpeg');
  };
  React.useImperativeHandle(mzRef, () => ({
    capture: () => {
      capture();
    },
  }));

  // POST motion zone to PCS Admin server
  const setEventNewDetectionMutation = useSetEventNewDetection();
  const handleSave = (data) => {
    if (data) {
      setEventNewDetectionMutation.mutate({
        eventId,
        pts_seconds: pts_seconds || 0,
        annotation: data.images[0],
        image: b64Image.current,
      });
    }
    setImage(null);
    onDone();
  };
  const { enqueueSnackbar } = useSnackbar();
  React.useEffect(() => {
    if (setEventNewDetectionMutation.isError) {
      enqueueSnackbar(`Error saving: ${setEventNewDetectionMutation.error}`);
    } else if (setEventNewDetectionMutation.isSuccess) {
      enqueueSnackbar(`Successfully saved`);
    }
    return () => {};
  }, [
    setEventNewDetectionMutation.isError,
    setEventNewDetectionMutation.isSuccess,
    setEventNewDetectionMutation.error,
    enqueueSnackbar,
  ]);

  return (
    <>
      {image && (
        <Box
          m={1}
          height="75vh"
          id="pcs-annotation-container"
          key="pcs-annotation-container"
        >
          <Annotator
            hideHeader
            regionClsList={['Person', 'Face', 'Package']}
            enabledTools={[
              'select',
              // 'create-point',
              'create-box',
              // 'create-polygon'
              // 'create-line',
              // 'create-expanding-line',
              // 'show-mask'
            ]}
            selectedTool="create-box"
            onExit={handleSave}
            images={[
              {
                src: image,
                name: '',
                regions: regions || [],
              },
            ]}
          />
        </Box>
      )}
    </>
  );
};

AnnotateImage.propTypes = {
  mzRef: PropTypes.object,
  playerElRef: PropTypes.object,
  onDone: PropTypes.func,
  eventId: PropTypes.number,
  pts_seconds: PropTypes.number,
  regions: PropTypes.array,
};

export default AnnotateImage;
