import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  createOpenMetricDispatch,
  MetricsContext,
} from 'src/context/Metrics-context';
import { SocketContext } from 'src/context/socket-context';

const INTERVAL_TIMER = 15 * 60 * 1000; // 15 mins

interface TimelineManagerProps {
  agentId: string;
  agentEmail: string;
}

const useEmptyEventQueueTimeMetric = ({
  agentId,
  agentEmail,
}: TimelineManagerProps) => {
  const [eventContainer] = useContext(SocketContext);
  const [_, dispatch] = useContext(MetricsContext);
  const openMetricDispatch = createOpenMetricDispatch(dispatch);
  const previousQueueLength = useRef<number>();
  const emptyQueueStartMarkRef = useRef<number>();
  const emptyQueueTimeRef = useRef(0);

  const queueLength = useMemo(
    () => eventContainer?.eventArray?.filter((e) => !!e.eventId).length ?? 0,
    [eventContainer?.eventArray]
  );
  const isQueueEmpty = queueLength === 0;

  const calculateEmptyQueueTime = useCallback(() => {
    const emptyQueueEndMark = Date.now();
    const duration = emptyQueueEndMark - emptyQueueStartMarkRef.current;
    emptyQueueTimeRef.current += duration;
    emptyQueueStartMarkRef.current = null;
  }, []);

  const submitEmptyQueueTime = useCallback(() => {
    if (emptyQueueStartMarkRef.current) {
      calculateEmptyQueueTime();
    }

    openMetricDispatch({
      metricName: 'agent-empty-queue-time',
      payload: {
        agentId,
        agentEmail,
        elapsedTime: Math.round(emptyQueueTimeRef.current / 1000),
      },
    });
    emptyQueueTimeRef.current = 0;
  }, [agentId, agentEmail]);

  const markQueueEmpty = () => {
    emptyQueueStartMarkRef.current = Date.now();
  };

  const retrigger = () => {
    if (isQueueEmpty) {
      markQueueEmpty();
    }
  };

  useEffect(() => {
    if (isQueueEmpty) {
      markQueueEmpty();
    }

    const hasNewEvent = queueLength > previousQueueLength.current;
    if (hasNewEvent && emptyQueueStartMarkRef.current) {
      calculateEmptyQueueTime();
    }
    return () => {
      previousQueueLength.current = queueLength;
    };
  }, [queueLength]);

  useEffect(() => {
    let intervalTimer = setInterval(() => {
      submitEmptyQueueTime();
    }, INTERVAL_TIMER);

    return () => {
      clearInterval(intervalTimer);
    };
  }, [submitEmptyQueueTime]);

  useEffect(() => {
    window.addEventListener('beforeunload', submitEmptyQueueTime);
    return () => {
      window.removeEventListener('beforeunload', submitEmptyQueueTime);
    };
  }, [submitEmptyQueueTime]);

  useEffect(() => {
    return () => {
      submitEmptyQueueTime();
    };
  }, []);

  return {
    submitEmptyQueueTimeMetric: submitEmptyQueueTime,
    retrigger,
  };
};

export default useEmptyEventQueueTimeMetric;
