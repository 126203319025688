import { makeStyles, TextField } from '@material-ui/core';
import { createOpenMetricDispatch } from 'src/context/Metrics-context';

interface Props {
  documentation: string;
  setDocumentation: any;
}

const useStyles = makeStyles({
  root: {
    width: 175,
  },
  eventText: {
    width: '100%',
    height: '100%',
    backgroundColor: 'transparent',
    '& .MuiInputBase-root': {
      backgroundColor: 'transparent',
      '& .MuiInputBase-input': {
        padding: 0,
      },
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'transparent',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: 'transparent',
    },
    '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: 'transparent',
    },
  },
});

export function EventDocumentation({ documentation, setDocumentation }: Props) {
  const classes = useStyles();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDocumentation(e.target.value);
  };

  return (
    <TextField
      id="event-doc-textfield"
      type="text"
      className={classes.eventText}
      inputProps={{ maxLength: 500 }}
      InputProps={{ style: { backgroundColor: 'transparent', height: '90%', overflow: 'auto', alignItems: 'flex-start' } }}
      variant="outlined"
      multiline
      value={documentation}
      onChange={handleChange}
      placeholder="Describe the Event"
    />
  );
}

interface CommitProps {
  eventId: number;
  dispatch: any;
  documentation: string;
  setDocumentationMutation: any;
}

export const handleChangeCommitted = ({
  documentation,
  setDocumentationMutation,
  eventId,
  dispatch,
}: CommitProps) => {
  setDocumentationMutation.mutate({ documentation });

  const openMetricDispatch = createOpenMetricDispatch(dispatch);
  openMetricDispatch({
    metricName: 'event-documentation-set',
    payload: {
      eventId,
      documentation,
    },
  });
};
