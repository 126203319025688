import React, { useState, useEffect, useRef, useContext } from 'react';
import {
  Container,
  Grid,
  makeStyles,
  Box,
  Card,
  Typography,
} from '@material-ui/core';
import DataStream from 'src/components/DataStream';
import Page from 'src/components/Page';
import { useVideoPlayer } from 'src/components/VideoPlayer/useVideoPlayer';
import DenseCardHeader from 'src/components/DenseCardHeader';
import { useQueryParams } from 'src/utils/useQueryParams';
import PcsCamera from '../verify-respond/PcsCamera';
import { useMostRecent } from 'src/utils/useMostRecent';
import Compose from 'src/components/mail/Compose';
import VideoActions from '../verify-respond/VideoActions';
import FollowUpSteps from './FollowUpSteps';
import MotionZone from 'src/components/MotionZone';
import AnnotateImage from 'src/components/AnnotateImage';
import { useGetPcsEventQuery } from 'src/hooks/eventHooks';
import ConfirmDialog from 'src/components/ConfirmDialog';
import { Navigate, useNavigate } from 'react-router';
import MetricsModule from 'src/utils/MetricsModule';
import { MetricsContext } from 'src/context/Metrics-context';
import { PushCompose } from 'src/components/pushNotification/PushCompose';
import { Role } from 'src/models';
import { IconButton, Collapse, Button, styled } from '@material-ui/core';
import { useGetRepMe } from 'src/hooks/repHooks';
import { useDisarmHook } from 'src/hooks/useDisarmHooks';
import { useSubscription } from 'src/hooks/useSubscription';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme) => ({
  root: {
    maxHeight: '100%',
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
}));

const ExpandPushNotification = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const FollowUpView = () => {
  const classes = useStyles();
  const { userId, eventId, sid } = useQueryParams();
  const { data: repData } = useGetRepMe();
  const navigate = useNavigate();
  const [_, dispatchMetrics] = useContext(MetricsContext);
  const metrics = new MetricsModule('FollowUp-metric');
  const payload = useRef(metrics.payload({ eventID: eventId }));
  const { enqueueSnackbar } = useSnackbar();
  const [dispositionSubtype, setDispositionType] = useState(
    'familiar_person_on_property'
  );
  const [expanded, setExpanded] = useState(true);
  const [isDisarmed, setIsDisarmed] = useState(false);
  const { subscription: realSubscription } = useSubscription({
    userId,
    sid,
  });

  const pushComposeRef = useRef();
  const emailComposeRef = useRef();

  useDisarmHook({
    eventId,
    isDisarmed,
    setIsDisarmed,
    dispatch: dispatchMetrics,
    userId,
    subscription: realSubscription,
  });

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    payloadMetricsData();
    return () => {
      metrics.stopTimer();
      payload.current = metrics.payload({ eventID: eventId });
      dispatchMetrics({ type: 'SEND', payload: payload.current });
    };
  }, []);

  const payloadMetricsData = () => {
    const watchData = JSON.parse(localStorage.getItem('pcs_watch_settings'));
    watchData
      ? metrics.fetchNewData(
          watchData.acceptNew,
          watchData.pcsStatusThreshold,
          watchData.threatLevel,
          watchData.armstate,
          eventId
        )
      : metrics.fetchNewData();
  };

  const { dispatch, ...playerState } = useVideoPlayer(userId, {
    isPlaying: false,
    volume: 0,
    controls: ['play', 'time', 'progress', 'volume'],
  });
  const [activeItem, setActiveItem] = React.useState();
  const [overRide, setOverRide] = useState(false);
  const [isEmailUntouched, setIsEmailUntouched] = useState(true);

  // Save params -- and redirect to most recent handled event if no params
  useMostRecent();

  const handleItemClick = (item) => {
    setActiveItem({ ...item, rand: Math.random() });
  };

  const [importantTracks, setImportantTracks] = React.useState([]);
  const handleImportant = (img_file) => {
    setImportantTracks([img_file]);
  };

  // MOTION ZONE or ANNOTATE
  const [videoAction, setVideoAction] = React.useState(null);
  const handleVideoAction = (actionId) => {
    setVideoAction(actionId);
    if (actionId === 'motion_zone') mzRef.current.capture();
    if (actionId === 'annotate') annotateRef.current.capture();
  };
  const playerElRef = React.useRef();
  const mzRef = React.useRef();
  const annotateRef = React.useRef();
  const handleReady = (playerEl) => {
    playerElRef.current = playerEl.current;
  };
  const pts_seconds = React.useRef(null);
  const handleProgress = (time) => {
    pts_seconds.current = time;
  };
  const handleZoneDone = () => {
    if (videoAction === 'motion_zone')
      dispatch({ type: 'FORCE_MOTION_ZONE_OVERLAY' });
    setVideoAction(null);
  };

  const eventQuery = useGetPcsEventQuery(eventId);

  const isFirstRenderLocked = useRef(false);

  useEffect(() => {
    if (eventQuery.data == null || isFirstRenderLocked.current) {
      return;
    }
    isFirstRenderLocked.current = true;

    if (
      repData?.role === Role.AGENT_OPS &&
      eventQuery?.data?.disposition?.action != null
    ) {
      dispatchMetrics({
        type: 'SEND',
        payload: {
          metricName: 'event-already-handled',
          payload: { eventId, agentID: repData?.user_id },
        },
      });
      enqueueSnackbar(
        `The event has been already handled [${eventQuery?.data?.disposition?.action}]`,
        {
          variant: 'info',
        }
      );
      navigate('/app/queue');
    }
  }, [eventQuery]);

  if (!eventId) return null;

  if (isDisarmed) {
    return (
      <ConfirmDialog
        message="Customer disarmed system, event has ended"
        cancelButtonText="RETURN TO QUEUE"
        continueButtonText={null}
        onCancel={() => {
          navigate('/app/queue');
        }}
      />
    );
  }

  if (repData?.role === Role.ENGINEER || repData?.role === Role.QA)
    return <Navigate to="/app/dashboard" />;

  return (
    <Page title="Follow Up">
      <Container className={classes.root} maxWidth={false}>
        <Grid container spacing={1}>
          {/* LEFT - VIDEO PLAYER */}
          <Grid item xs={6}>
            <Card>
              <Box height="50vh" position="relative">
                <PcsCamera // prettier-ignore
                  eventId={eventId}
                  playerState={{ ...playerState }}
                  activeItem={activeItem}
                  hide={!!videoAction}
                  onReady={handleReady}
                  onProgress={handleProgress}
                  liveIndicator={false}
                  eventQuery={eventQuery}
                  view={'follow-up'}
                />
                <VideoActions onClickAction={handleVideoAction} />
                <MotionZone
                  mzRef={mzRef}
                  playerElRef={playerElRef}
                  onDone={handleZoneDone}
                />
                <AnnotateImage
                  eventId={eventId}
                  mzRef={annotateRef}
                  playerElRef={playerElRef}
                  pts_seconds={pts_seconds.current}
                  onDone={handleZoneDone}
                />
              </Box>
            </Card>
          </Grid>

          {/* RIGHT - DATASTREAM */}
          <Grid item xs={6}>
            <Card>
              <Box height="50vh">
                <DenseCardHeader title="Datastream" />
                <Box height="100%" p={1} overflow="hidden">
                  {!eventId && <Typography>No event</Typography>}
                  {eventId && (
                    <DataStream
                      eventId={eventId}
                      onItemClick={handleItemClick}
                      isSelectingPeople={true}
                      onImportant={handleImportant}
                      showCluster
                      eventQuery={eventQuery}
                    />
                  )}
                </Box>
              </Box>
            </Card>
          </Grid>

          {/* LEFT - USER NOTES */}
          <Grid item xs={6}>
            <Card>
              <DenseCardHeader
                title="Email Message"
                // second={`Account: ${subscription.location.account}`}
              />
              <Box height="100%">
                <Compose
                  ref={emailComposeRef}
                  setIsEmailUntouched={setIsEmailUntouched}
                  importantTracks={importantTracks}
                  eventQuery={eventQuery}
                  dispositionSubtype={dispositionSubtype}
                  setDispositionType={setDispositionType}
                />
              </Box>
            </Card>
          </Grid>

          {/* RIGHT - DISPOSITION */}
          <Grid item xs={4}>
            <Card>
              <Box style={{ display: 'flex' }}>
                <DenseCardHeader title="Push Notification Message" />
                {!expanded && (
                  <ExpandPushNotification
                    expand={expanded}
                    onClick={handleExpandClick}
                    aria-expanded={expanded}
                    aria-label="show more"
                  >
                    <Button
                      color="primary"
                      variant="contained"
                      style={{ height: 25 }}
                    >
                      Send again
                    </Button>
                  </ExpandPushNotification>
                )}
              </Box>
              <Collapse in={expanded} timeout="auto" unmountOnExit>
                <Box height="100%" marginTop="3px">
                  <PushCompose
                    ref={pushComposeRef}
                    eventId={eventId}
                    eventQuery={eventQuery}
                    dispositionSubtype={dispositionSubtype}
                    setExpanded={setExpanded}
                  />
                </Box>
              </Collapse>
            </Card>
          </Grid>
          <Grid item xs={2}>
            <FollowUpSteps
              eventQuery={eventQuery}
              metrics={metrics}
              dispatch={dispatchMetrics}
              isEmailUntouched={isEmailUntouched}
              setIsEmailUntouched={setIsEmailUntouched}
              onDone={() => {
                pushComposeRef.current.send();
                emailComposeRef.current.send();
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default FollowUpView;
