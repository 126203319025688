import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  makeStyles,
} from '@material-ui/core';
import {
  useGetStatusService,
  useSubscriberList,
} from 'src/hooks/subscriberHooks';
import { LoadingSpinner } from 'src/components/LoadingSpinner';
import Toolbar from './Toolbar';
import { SubsTable } from './SubsTable';
import { Navigate } from 'react-router';
import { useGetRepMe } from 'src/hooks/repHooks';

const useStyles = makeStyles((theme) => ({
  avatar: {
    marginRight: theme.spacing(2),
  },
}));

const cellsData = [
  { id: 1, value: 'User (user id)' },
  { id: 2, value: 'Manage Service' },
  { id: 3, value: 'Email' },
  { id: 4, value: 'Authenticated' },
  { id: 5, value: 'Auth Failures' },
  { id: 6, value: 'Last Failure' },
  { id: 7, value: 'Service' },
];

const SubscriberList = ({ className, ...rest }) => {
  const classes = useStyles();
  const [selectedCustomerIds, setSelectedCustomerIds] = useState([]);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const [searchValue, setSearchValue] = useState('');
  const { data: repData } = useGetRepMe();

  let { data = [], isLoading } = useSubscriberList(
    page * limit,
    limit,
    searchValue
  );

  const handleSelectAll = (event) => {
    let newSelectedCustomerIds;

    if (event.target.checked) {
      newSelectedCustomerIds = data.map((customer) => customer.id);
    } else {
      newSelectedCustomerIds = [];
    }

    setSelectedCustomerIds(newSelectedCustomerIds);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedCustomerIds.indexOf(id);
    let newSelectedCustomerIds = [];

    if (selectedIndex === -1) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(
        selectedCustomerIds,
        id
      );
    } else if (selectedIndex === 0) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(
        selectedCustomerIds.slice(1)
      );
    } else if (selectedIndex === selectedCustomerIds.length - 1) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(
        selectedCustomerIds.slice(0, -1)
      );
    } else if (selectedIndex > 0) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(
        selectedCustomerIds.slice(0, selectedIndex),
        selectedCustomerIds.slice(selectedIndex + 1)
      );
    }

    setSelectedCustomerIds(newSelectedCustomerIds);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  if (repData?.role === 'agent_ops') {
    return <Navigate to="/app/dashboard" />;
  }

  return (
    <>
      <Toolbar
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        isExportEnabled={data.length > 0}
      />

      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <Card className={clsx(classes.root, className)} {...rest}>
          <PerfectScrollbar>
            <Box minWidth={1050}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={selectedCustomerIds.length === data.length}
                        color="primary"
                        indeterminate={
                          selectedCustomerIds.length > 0 &&
                          selectedCustomerIds.length < data.length
                        }
                        onChange={handleSelectAll}
                      />
                    </TableCell>
                    {cellsData.map((cell) => (
                      <TableCell key={cell.id}>{cell.value}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.map((customer, idx) => (
                    <SubsTable
                      key={idx}
                      customer={customer}
                      selectedCustomerIds={selectedCustomerIds}
                      handleSelectOne={handleSelectOne}
                      userRole={repData?.role}
                    />
                  ))}
                </TableBody>
              </Table>
            </Box>
          </PerfectScrollbar>
          <TablePagination
            component="div"
            count={-1}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleLimitChange}
            page={page}
            rowsPerPage={limit}
            rowsPerPageOptions={[5, 10, 25, 100]}
          />
        </Card>
      )}
    </>
  );
};

SubscriberList.propTypes = {
  className: PropTypes.string,
};

export default SubscriberList;
