import React, { useContext, useRef, useState, useEffect } from 'react';
import { Container, Grid, makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import { LoadingSpinner } from 'src/components/LoadingSpinner';
import {
  useTakePcsEventsQuery,
  useUnsetEventsTaken,
} from 'src/hooks/eventHooks';
import { Theme } from 'src/theme/index';
import MonitorItem from './MonitorItem';
import EmptyMonitorItem from './EmptyMonitorItem';
import { useVideoPlayer } from 'src/components/VideoPlayer/useVideoPlayer';
import { useGetRepMe, useGetQueueSettings } from 'src/hooks/repHooks';
import { setEventsDataAC } from 'src/context/socket-context';
import { SocketContext } from 'src/context/socket-context';
import { Navigate } from 'react-router';
import MetricsModule from 'src/utils/MetricsModule';
import {
  MetricsContext,
  createOpenMetricDispatch,
  getEventInQueueDuration,
} from 'src/context/Metrics-context';
import { QueueControls } from './QueueControls';
import { Role } from 'src/models';
import { FullStory as FS } from '@fullstory/browser';
import useEmptyEventQueueTimeMetric from './useEmptyEventQueueTimeMetric';
import { useEventQueueConcurrencyMetric } from './useEventQueueConcurrencyMetric';

export enum AlarmState {
  ALL = 'All',
  UNARMED = 'Unarmed',
  ARMED = 'Armed',
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(0),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 100,
  },
}));

const MonitorView = () => {
  const classes = useStyles();
  const { data: repData } = useGetRepMe();
  const [eventArray2, socketdispatch] = useContext(SocketContext);
  const metrics = new MetricsModule('queue time');
  const { submitEmptyQueueTimeMetric, retrigger: retriggerEmptyQueueMetric } =
    useEmptyEventQueueTimeMetric({
      agentId: repData?.id,
      agentEmail: repData?.email,
    });
  const [_, dispatch] = useContext(MetricsContext);
  const payload = useRef(metrics.payload());

  // Get settings from localstorage
  const pcs_watch_settings = useGetQueueSettings(repData?.id);
  const [limit, setLimit] = useState(pcs_watch_settings.data?.limit);
  const [acceptNew, setAcceptNew] = useState(
    pcs_watch_settings.data?.acceptNew
  );
  const [lookback, setLookback] = useState(pcs_watch_settings.data?.lookback);
  const [autoExpire, setAutoExpire] = useState(
    pcs_watch_settings.data?.autoExpire
  );
  const [pcsStatusThreshold, setPcsStatusThreshold] = useState(
    pcs_watch_settings.data?.pcsStatusThreshold
  );
  const [threatLevel, setThreatLevel] = useState(
    pcs_watch_settings.data?.threatLevel
  );
  const [armstate, setArmstate] = useState(pcs_watch_settings.data?.armstate);
  const [experimental, setExperimental] = useState(false);

  // Identify the user to FullStory
  React.useEffect(() => {
    if (repData) {
      FS('setIdentity', {
        uid: repData?.user_id,
        properties: {
          userId: repData?.user_id,
          email: repData?.email,
          name: `${repData?.firstName} ${repData?.lastName}`,
        },
      });
    }
  }, []); // Send every time the component mounts, will be more than strictly necessary but will help capture IDs

  // immediately send empty queue time metric when not accepting new events
  React.useEffect(() => {
    if (acceptNew) {
      retriggerEmptyQueueMetric();
    } else {
      submitEmptyQueueTimeMetric();
    }
  }, [acceptNew]);

  // Save settings on any change
  React.useEffect(() => {
    localStorage.setItem(
      'pcs_watch_settings',
      JSON.stringify({
        limit: 9,
        acceptNew: false,
        lookback: 60 * 10,
        autoExpire: 60 * 2,
        pcsStatusThreshold: 0,
        threatLevel: 0,
        armstate: 'Armed',
      })
    );
    metrics.fetchNewData(acceptNew, pcsStatusThreshold, threatLevel, armstate);
    payload.current = metrics.payload();
  }, [
    limit,
    acceptNew,
    lookback,
    autoExpire,
    pcsStatusThreshold,
    threatLevel,
    armstate,
  ]);

  React.useEffect(() => {
    setLimit(pcs_watch_settings.data?.limit),
      setAcceptNew(pcs_watch_settings.data?.acceptNew),
      setLookback(pcs_watch_settings.data?.lookback),
      setAutoExpire(pcs_watch_settings.data?.autoExpire),
      setPcsStatusThreshold(pcs_watch_settings.data?.pcsStatusThreshold),
      setThreatLevel(pcs_watch_settings.data?.threatLevel),
      setArmstate(pcs_watch_settings.data?.armstate);
  }, [pcs_watch_settings.data]);

  // Get events
  const eventsQuery = useTakePcsEventsQuery(
    limit,
    acceptNew,
    lookback,
    pcsStatusThreshold,
    threatLevel,
    armstate
  );

  const visibilityHandler = () => {
    document.visibilityState === 'hidden' ? setAcceptNew(false) : null;
  };

  React.useEffect(() => {
    socketdispatch(setEventsDataAC(eventsQuery.data, acceptNew));
  }, [eventsQuery.data]);

  // Leaving the page - "un-take" all events.  If selected an event to verify, that gets stored in eventClickedRef to keep handling it
  const eventClickedRef = React.useRef<number | null>(null);
  const unsetTakenMutation = useUnsetEventsTaken();
  const unsetMutate = unsetTakenMutation.mutate;
  React.useEffect(() => {
    document.addEventListener('visibilitychange', visibilityHandler);

    const unsetEvents = () => {
      unsetMutate({ verify_eventId: eventClickedRef.current });

      const eventId = eventClickedRef.current;

      // Send metric for how long the event was in the queue
      if (eventId) {
        const openMetricDispatch = createOpenMetricDispatch(dispatch);
        openMetricDispatch({
          metricName: 'event-handle-time',
          payload: {
            eventId,
            duration: getEventInQueueDuration(eventId),
            PCSstatus: 'queue',
          },
        });
      }
    };

    // Also need to cleanup on tab closing
    window.addEventListener('beforeunload', unsetEvents);

    return () => {
      setAcceptNew(false);
      localStorage.setItem(
        'pcs_watch_settings',
        JSON.stringify({
          limit,
          acceptNew: false,
          lookback,
          autoExpire,
          pcsStatusThreshold,
          threatLevel,
          armstate,
        })
      );
      if (payload.current.send) {
        metrics.stopTimer();
        payload.current.elapsedTime = metrics.payload().elapsedTime;
        dispatch({
          type: 'SEND',
          payload: { ...payload.current, eventID: eventClickedRef.current },
        });
      }

      // When we leave the queue page - even to go to the verify page - we need to clean up watched events
      unsetEvents();
      window.removeEventListener('beforeunload', unsetEvents);
      document.removeEventListener('visibilitychange', visibilityHandler);
    };
  }, [eventClickedRef]);

  const { submitConcurrencyMetric } = useEventQueueConcurrencyMetric({
    events: eventArray2.eventArray,
  });

  const [eventsHandled, setEventsHandled] = React.useState(0);
  const { ...playerState } = useVideoPlayer(null, { isPlaying: true });
  if (repData?.role === Role.ENGINEER || repData?.role === Role.QA)
    return <Navigate to="/app/dashboard" />;
  if (!eventsQuery.isSuccess || !pcs_watch_settings.isSuccess)
    return <LoadingSpinner />;

  return (
    <Page className={classes.root} title="Queue">
      <Container maxWidth={false}>
        <Grid container spacing={1}>
          <Grid item xs={10}>
            <Grid
              container
              spacing={1}
              alignItems="stretch"
              justifyContent="center"
            >
              {eventArray2.eventArray?.map((event: any) => (
                <Grid
                  item
                  xs={limit === 1 ? 10 : limit === 3 ? 5 : 4}
                  key={`${event.handler.slot}-${event.eventId}`}
                  style={{
                    minHeight:
                      limit === 1
                        ? 'calc(90vh - 60px)'
                        : limit === 3
                        ? 'calc(50vh - 60px)'
                        : 'auto',
                  }}
                >
                  {event.eventId ? (
                    <MonitorItem
                      event={event}
                      slot={event.handler.slot}
                      playerState={playerState}
                      autoExpire={autoExpire}
                      onClose={() => {
                        submitConcurrencyMetric(event.eventId);
                        setEventsHandled(eventsHandled + 1);
                      }}
                      onPlayerClick={(eventId) => {
                        eventClickedRef.current = eventId;
                      }}
                      payload={payload.current}
                      experimental={experimental}
                    />
                  ) : (
                    <EmptyMonitorItem slot={event.handler.slot} />
                  )}
                </Grid>
              ))}
            </Grid>
          </Grid>
          <QueueControls
            armstate={armstate}
            setArmstate={setArmstate}
            lookback={lookback}
            setLookback={setLookback}
            threatLevel={threatLevel}
            setThreatLevel={setThreatLevel}
            pcsStatusThreshold={pcsStatusThreshold}
            setPcsStatusThreshold={setPcsStatusThreshold}
            autoExpire={autoExpire}
            setAutoExpire={setAutoExpire}
            limit={limit}
            setLimit={setLimit}
            classes={classes}
            acceptNew={acceptNew}
            setAcceptNew={setAcceptNew}
            experimental={experimental}
            setExperimental={setExperimental}
          />
        </Grid>
      </Container>
    </Page>
  );
};

export default MonitorView;
