import React, { ReactNode, useContext, WheelEventHandler } from 'react';
import LiveIcon from '@material-ui/icons/RssFeed';
import PlaybackIcon from '@material-ui/icons/Movie';
import { Box, makeStyles } from '@material-ui/core';


const LiveIndicatorContext = React.createContext<{
  showLiveIndicator?: boolean;
  liveIndicator: boolean;
}>({
  showLiveIndicator: true,
  liveIndicator: true,
});

export interface LiveIndicatorProviderProps {
  showLiveIndicator?: boolean;
  liveIndicator: boolean;
  children: ReactNode;
}

export interface AddLiveIndicatorProps {
  hasVideo?: boolean;
  children: ReactNode;
  onScrollZoom?: WheelEventHandler<HTMLDivElement>;
}

export function useLiveIndicator() {
  return useContext(LiveIndicatorContext).liveIndicator;
}

export function useShowLiveIndicator() {
  return useContext(LiveIndicatorContext).showLiveIndicator;
}

export function LiveIndicatorProvider({
  showLiveIndicator = true,
  liveIndicator,
  children,
}: LiveIndicatorProviderProps) {
  return (
    <LiveIndicatorContext.Provider value={{ liveIndicator, showLiveIndicator }}>
      {children}
    </LiveIndicatorContext.Provider>
  );
}

export function AddLiveIndicator({
  hasVideo = true,
  children,
  onScrollZoom,
}: AddLiveIndicatorProps) {
  const classes = useStyles();
  const liveIndicator = useLiveIndicator();
  const show = useShowLiveIndicator();

  return (
    <div onWheelCapture={onScrollZoom}>
      {show && hasVideo && (
        <div className={classes.videoType}>
          {liveIndicator ? (
            <div className={classes.videoTypeIndicator}>
              <LiveIcon />
              <div>Live</div>
            </div>
          ) : (
            <Box sx={{'filter': 'blur(5px)'}}>
              <div className={classes.videoTypeIndicator}>
                <PlaybackIcon />
                <div>Playback</div>
              </div>
            </Box>
          )}
        </div>
      )}
      <div className='fs-exclude'>
        {/* Exclude video from FullStory only at the last possible moment so that we can see as video is loading */}
        {children}
      </div>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  videoType: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
    zIndex: 100,
  },
  videoTypeIndicator: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(0.2),
    backgroundColor: theme.palette.grey[600],
    fontFamily: theme.typography.fontFamily,
    padding: theme.spacing(0.5),
    borderRadius: theme.spacing(1),
  },
}));
