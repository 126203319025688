// props used by flv.js defined in PropTypes here, but not used here
/* eslint-disable react/no-unused-prop-types */

// Based on: https://github.com/gwuhaolin/reflv/blob/master/src/index.js
// Alternatives: https://github.com/BingXiong1995/react-flv-player/blob/master/lib/wrapper/ReactFlvPlayer.js, https://github.com/qwertyyb/video-player-react/blob/master/src/BasePlayer.js
// Requires: https://github.com/bilibili/flv.js/blob/master/docs/livestream.md

// Useful doc: https://github.com/bilibili/flv.js/blob/master/docs/api.md

// For Native, consider: https://www.npmjs.com/package/react-native-live-stream

import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import flvjs from 'flv.js';
import { useSnackbar } from 'notistack';
import { ScreenContext } from 'src/context/ScreenRecordingContext';
import { log } from 'src/context/Logs';

const FlvPlayer = React.forwardRef((props, ref) => {
  const {
    className,
    style,
    controls,
    url,
    isLive,
    config,
    onError,
    uuid,
    sid,
  } = props;
  const { enqueueSnackbar } = useSnackbar();
  // Flag to only .load() video on inital render
  const isInitialMount = useRef(true);
  const thisFlvPlayer = useRef();
  const audioContext = useRef();
  const videoSource = useRef();
  const destination = useRef();
  const { remoteStream, setRemoteStream } = React.useContext(ScreenContext);

  // INITIAL LOAD
  useEffect(() => {
    let thisPlayer;

    const initFlv = async ($video) => {
      if ($video) {
        if (flvjs.isSupported()) {
          try {
            console.log('FlvPlayer Init');

            if (isLive) {
              const response = await fetch(
                `${process.env.REACT_APP_PCS_ADMIN_URL}/camera/${uuid}/${sid}/flv-token`,
                {
                  headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                  },
                }
              );

              const jsonResponse = await response.json();
              sessionStorage.setItem(`token-${uuid}`, jsonResponse.token);

              config.headers = {
                Authorization: `Bearer ${jsonResponse.token}`,
              };

              if (jsonResponse['ip_forward']) {
                config.headers['X-Forwarded-For'] = jsonResponse['ip_forward']; //requires running with CORS disabled
              }
            } else {
              console.log('Using flv player for recorded video');
            }

            const flvPlayer = flvjs.createPlayer(
              {
                type: 'flv',
                cors: true,
                hasAudio: true,
                hasVideo: true,
                url,
                isLive,
                enableWorker: false,
                autoCleanupSourceBuffer: true,
                enableStashBuffer: false,
              },
              config
            );

            flvPlayer.on(flvjs.Events.MEDIA_INFO, () => {
              audioContext.current = new AudioContext();
              videoSource.current =
                audioContext.current.createMediaElementSource($video);
              destination.current =
                audioContext.current.createMediaStreamDestination();
              videoSource.current.connect(destination.current);
              videoSource.current.connect(audioContext.current.destination);
              if (destination.current.stream) {
                setRemoteStream({
                  ...remoteStream,
                  ...{ [uuid]: destination.current.stream },
                });
              }
            });

            flvPlayer.attachMediaElement($video);
            flvPlayer.load();

            // For reference, check this:
            // https://github.com/bilibili/flv.js/blob/master/docs/api.md#flvjserrortypes
            flvPlayer.on(
              flvjs.Events.ERROR,
              (error, details, { code, msg }) => {
                handleError(error, details, { code, msg });
              }
            );

            thisPlayer = flvPlayer;
            thisFlvPlayer.current = flvPlayer;
          } catch (error) {
            console.log('CAUGHT ERROR', error);
          }
        }
      }
    };

    (async () => {
      // Load player
      if (isInitialMount.current) {
        isInitialMount.current = false;
        await initFlv(ref.current);
      }
    })();

    // Handle errors
    const handleError = (error, details, { code, msg }) => {
      console.log('FLV Error details', error, details);
      log({
        message: `on FlvPlayer: error while playing ${url}`,
        data: {
          error,
          details,
          url,
          isLive,
        },
      });
      onError(code, msg);
    };

    // Cleanup
    return () => {
      console.log('FlvPlayer Cleanup - destroy players');
      if (thisFlvPlayer.current) {
        sessionStorage.removeItem(`token-${uuid}`);
        // flvjs.BaseLoader.abort();

        thisPlayer.pause();
        // thisPlayer.unload();
        // thisPlayer.detachMediaElement();
        thisPlayer.destroy();
        thisPlayer = null;
        thisFlvPlayer.current = null;
      }
    };
    // Note: we really want to load and mount this only on the first render:
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <video
      className={className}
      controls={controls}
      style={{
        width: '100%',
        backgroundColor: '#111',
        ...style,
      }}
      ref={ref}
      preload="auto"
    />
  );
});

FlvPlayer.propTypes = {
  controls: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.object,
  onError: PropTypes.func,
  /**
   * media URL, can be starts with 'https(s)' or 'ws(s)' (WebSocket)
   */
  url: PropTypes.string.isRequired,
  /**
   * media type, 'flv' or 'mp4'
   */
  type: PropTypes.oneOf(['flv', 'mp4']).isRequired,
  /**
   * whether the data source is a **live stream**
   */
  isLive: PropTypes.bool.isRequired,
  /**
   * whether to enable CORS for http fetching
   */
  cors: PropTypes.bool,
  /**
   * whether to do http fetching with cookies
   */
  withCredentials: PropTypes.bool,
  /**
   * whether the stream has audio track
   */
  hasAudio: PropTypes.bool,
  /**
   * whether the stream has video track
   */
  hasVideo: PropTypes.bool,
  /**
   * total media duration, in milliseconds
   */
  duration: PropTypes.bool,
  /**
   * total file size of media file, in bytes
   */
  filesize: PropTypes.number,
  /**
   * Optional field for multipart playback, see MediaSegment
   */
  segments: PropTypes.arrayOf(
    PropTypes.shape({
      /**
       * indicates segment duration in milliseconds
       */
      duration: PropTypes.number.isRequired,
      /**
       * indicates segment file size in bytes
       */
      filesize: PropTypes.number,
      /**
       * indicates segment file URL
       */
      url: PropTypes.string.isRequired,
    })
  ),
  /**
   * @see https://github.com/Bilibili/flv.js/blob/master/docs/api.md#config
   */
  config: PropTypes.object,
};

export default FlvPlayer;
