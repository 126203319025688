import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import {
  Paper,
  Box,
  Typography,
  Divider,
  Input,
  Button,
  makeStyles,
  TextField,
} from '@material-ui/core';
import clsx from 'clsx';
import { useSendPushNotification } from 'src/hooks/subscriberHooks';
import { useQueryParams } from 'src/utils/useQueryParams';
import MetricsModule from 'src/utils/MetricsModule';
import { useGetRepMe } from 'src/hooks/repHooks';
import { Copy } from './PushCopy';
import { LoadingSpinner } from '../LoadingSpinner';
import { MetricsContext } from 'src/context/Metrics-context';
import { useSnackbar } from 'notistack';
import { getCopyFromDisposition } from './PushCopy';

import {
  getNotificationCategoryFromDispositionTypes,
  getNotificationCriticalityFromDispositionTypes,
} from 'src/utils/utils';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(0.5),
    outline: 'none',
    zIndex: 2000,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  fullScreen: {
    height: '100%',
    width: '100%',
    maxWidth: `calc(100% - ${theme.spacing(6)}px)`,
    maxHeight: `calc(100% - ${theme.spacing(6)}px)`,
    position: 'fixed',
    bottom: 0,
    right: 0,
    '& .ql-container .ql-editor': {
      height: '80vh',
    },
  },
  input: {
    width: '100%',
  },
  editor: {
    flexGrow: 1,
    '& .ql-editor': {
      height: 202,
    },
  },
  action: {
    marginRight: theme.spacing(1),
  },
}));

export const PushComposeComponent = (
  { eventId, eventQuery, dispositionSubtype, setExpanded },
  ref
) => {
  const classes = useStyles();
  const { userId, uuid } = useQueryParams();
  const metrics = new MetricsModule('send-push');
  const payload = useRef(metrics.payload({ eventID: eventId }));
  const [inputValue, setInputValue] = useState('');
  const [messageBody, setMessageBody] = useState('');
  const sendPushNotification = useSendPushNotification();
  const repInfo = useGetRepMe();
  const { enqueueSnackbar } = useSnackbar();
  const disposition = eventQuery?.data?.disposition?.action || null;
  const [_, dispatchMetrics] = useContext(MetricsContext);
  const event = eventQuery.data;

  // Only Update until we get a message body
  useEffect(() => {
    if (event && messageBody === '') {
      const { title, body } = getCopyFromDisposition(event, dispositionSubtype);

      if (typeof disposition === 'string' && repInfo.data) {
        const copy = Copy(eventId, repInfo, event);
        const newMessageBody = copy[disposition] || '';
        setMessageBody(newMessageBody);
        payload.current = metrics.payload({ eventID: eventId });
        dispatchMetrics({
          type: 'SEND',
          payload: {
            ...payload.current,
            value: newMessageBody,
            metricName: 'follow-up-disposition-set',
            eventID: eventId,
          },
        });
        setInputValue(title);
        setMessageBody(body);
      }
    }
  }, [event]);

  // Update once we have an event whenever we change disposition subtype
  useEffect(() => {
    if (event) {
      const { title, body } = getCopyFromDisposition(event, dispositionSubtype);

      setInputValue(title);
      setMessageBody(body);
    }
  }, [dispositionSubtype]);

  const handleChange = (evt) => {
    setMessageBody(evt.target.value);
  };

  const onSendBtnClickHandler = async () => {
    if (!messageBody) {
      return;
    }

    const pushNotificationResponse = await sendPushNotification.mutateAsync({
      user_id: userId,
      message: messageBody,
      title: inputValue,
      event_id: eventId,
      category: getNotificationCategoryFromDispositionTypes(dispositionSubtype),
      critical:
        getNotificationCriticalityFromDispositionTypes(dispositionSubtype),
    });

    let message;
    let variant;

    if (pushNotificationResponse.is_duplicated) {
      message = 'Push Notification already sent to this user for this event';
    } else if (pushNotificationResponse.is_skipped) {
      message = 'User has opted out of push notifications';
    } else if (pushNotificationResponse.delivered) {
      message = 'Push notification sent successfully';
      variant = 'success';
    }

    enqueueSnackbar(message, { variant });

    payload.current = metrics.payload({ eventID: eventId });
    dispatchMetrics({
      type: 'SEND',
      payload: {
        ...payload.current,
        metricName: 'send-push',
        eventID: eventId,
        agentID: localStorage.getItem('id'),
      },
    });
  };

  useImperativeHandle(ref, () => ({
    send: onSendBtnClickHandler,
  }));

  useEffect(() => {
    if (sendPushNotification.isSuccess) {
      setExpanded(false);
    }
  }, [sendPushNotification.isSuccess]);

  if (!repInfo.isSuccess && typeof eventQuery?.data !== 'object')
    return <LoadingSpinner message="Getting rep name" />;
  return (
    <Paper
      className={clsx(classes.root, { [classes.fullScreen]: false })}
      elevation={12}
    >
      <Box p={0.5} display="flex">
        <Typography color="textSecondary" style={{ marginRight: '5px' }}>
          Title:
        </Typography>
        <Input
          className={classes.input}
          disableUnderline
          placeholder="Person Detected"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          inputProps={{ maxLength: 65 }}
        />
      </Box>
      <Divider />
      <TextField
        id="filled-multiline-flexible"
        label="Description"
        multiline
        value={messageBody}
        onChange={handleChange}
        variant="filled"
        size="medium"
        style={{ height: '29.5vh' }}
        inputProps={{ maxLength: 178 }}
      />
      <Divider />
    </Paper>
  );
};

export const PushCompose = forwardRef(PushComposeComponent);
