import { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';
import { useKey } from 'src/context/hotkey-context';
import {
  useSetDispositionAction,
  useSetDispositionThreatLevel,
} from 'src/hooks/eventHooks';

interface Action {
  id: string;
  text: string;
}

const actions: Action[] = [
  { id: 'canceled_rep', text: 'Canceled by rep' },
  { id: 'canceled_ai', text: 'Canceled by AI' },
  { id: 'canceled_user_app', text: 'Canceled by user (app)' },
  { id: 'canceled_safe_word', text: 'Canceled by safe word' },
  { id: 'agent_handled', text: 'Agent Handled' },
  { id: 'dispatched_police', text: 'Dispatched police' },
  { id: 'dispatched_fire', text: 'Dispatched fire' },
  { id: 'dispatched_medical', text: 'Dispatched medical' },
  { id: 'dispatched_potential_fire', text: 'Dispatched potential fire' },
  { id: 'dispatched_suspicious_activity', text: 'Dispatched suspicious activity' },
  { id: 'canceled_by_customer', text: 'Canceled by customer' },
  { id: 'paused_monitoring', text: 'Paused monitoring' },
];

interface Props {
  eventId: number;
  metrics: any;
  dispatch: any;
  threatLevel?: number | undefined;
  actionId?: string;
}

export function Disposition({ eventId, actionId, metrics, dispatch }: Props) {
  const [value, setValue] = useState<Action | null>(
    actions.find((action) => action.id === actionId) || null
  );
  const [inputValue, setInputValue] = useState('');

  useKey(
    ['c r', 'c s', 'd p', 'd f', 'd m'],
    (e: any, combo: string) => {
      const key = combo.slice(-1);
      if (key === 'r')
        setValue(
          actions.find((action) => action.id === 'canceled_rep') || null
        );
      if (key === 's')
        setValue(
          actions.find((action) => action.id === 'canceled_safe_word') || null
        );
      if (key === 'p')
        setValue(
          actions.find((action) => action.id === 'dispatched_police') || null
        );
      if (key === 'f')
        setValue(
          actions.find((action) => action.id === 'dispatched_fire') || null
        );
      if (key === 'm')
        setValue(
          actions.find((action) => action.id === 'dispatched_medical') || null
        );
    },
    'Set Disposition'
  );

  const setActionMutation = useSetDispositionAction(eventId);

  useEffect(() => {
    setValue(actions.find((action) => action.id === actionId) || null);
  }, [actionId]);

  return (
    <Autocomplete
      closeIcon={null}
      aria-required
      value={value}
      onChange={(event: any, newValue: Action | null) => {
        if (!newValue) {
          return;
        }
        metrics.fetchNewData();
        const payload = {
          ...metrics.payload({ eventID: eventId }),
          metricName: 'Disposition-changed',
          disposition: newValue ? newValue?.id : null,
        };
        localStorage.setItem('disposition', newValue ? newValue?.id : null);
        dispatch({
          type: 'SEND',
          payload: payload,
        });
        setValue(newValue);
        if (newValue) setActionMutation.mutate({ action: newValue.id });
      }}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      options={actions}
      getOptionLabel={(option) => option.text}
      style={{ width: 225 }}
      renderInput={(params) => (
        <TextField {...params} label="Action" variant="standard" />
      )}
    />
  );
}

const useStyles = makeStyles({
  root: {
    width: 175,
  },
});

export function ThreatLevel({
  eventId,
  threatLevel,
  metrics,
  dispatch,
}: Props) {
  const classes = useStyles();
  const marks = [
    {
      value: 0,
      label: 'low',
    },
    {
      value: 4,
      label: 'high',
    },
  ];

  const setThreatMutation = useSetDispositionThreatLevel(eventId);

  const [value, setValue] = useState<number | undefined>(0);

  useKey(
    ['0', '1', '2', '3', '4'],
    (e: any, combo: string) => setValue(+combo.slice(-1)),
    'Set Threat level'
  );

  const handleChangeCommitted = (event: any, newValue: number | number[]) => {
    setThreatMutation.mutate({ threat_level: newValue as number });
    metrics.fetchNewData();
    const disposition = localStorage.getItem('disposition');
    const payload = {
      ...metrics.payload({ eventID: eventId }),
      metricName: 'follow-up-threat-level-set',
      disposition: disposition ? disposition : null,
    };
    dispatch({
      type: 'SEND',
      payload: payload,
    });
  };

  const handleChange = (event: any, newValue: number | number[]) => {
    setValue(newValue as number);
  };

  useEffect(() => {
    if (threatLevel) setValue(threatLevel);
  }, [threatLevel]);

  return (
    <div className={classes.root}>
      <Typography id="discrete-slider" variant="caption" color="textSecondary">
        Threat Level
      </Typography>
      <Slider
        value={value}
        onChangeCommitted={handleChangeCommitted}
        onChange={handleChange}
        valueLabelDisplay="auto"
        step={1}
        marks={marks}
        min={0}
        max={4}
      />
    </div>
  );
}
