import React from 'react';
import { Link, Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  makeStyles,
  IconButton,
} from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import WebIcon from '@material-ui/icons/Web';
import Label from 'src/components/Label';
import { useListPcsEventsQuery } from 'src/hooks/eventHooks';
import { LoadingSpinner } from 'src/components/LoadingSpinner';
import { mapPcsEventStatus } from 'src/utils/format';
import { useGetRepMe } from 'src/hooks/repHooks';

const labelColors = {
  complete: 'success',
  pending: 'warning',
  rejected: 'error',
};

const useStyles = makeStyles(() => ({
  root: {},
}));

const EventList = ({ className, ...rest }) => {
  const classes = useStyles();
  const eventsQuery = useListPcsEventsQuery({
    limit: 50,
    lookback: 3600 * 24 * 5,
  });
  const events = eventsQuery.data;
  const { data: repData } = useGetRepMe();

  if (!eventsQuery.isSuccess) return <LoadingSpinner />;
  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <PerfectScrollbar>
        <Box minWidth={700}>
          <Table>
            <TableHead>
              <TableRow>
                {repData?.role === 'admin' && (
                  <TableCell sortDirection="desc">
                    <Tooltip enterDelay={300} title="Sort">
                      <TableSortLabel active direction="desc">
                        Event ID
                      </TableSortLabel>
                    </Tooltip>
                  </TableCell>
                )}
                <TableCell>Description</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Alarm State</TableCell>
                <TableCell>Objects</TableCell>
                <TableCell>Movement</TableCell>
                <TableCell>Faces</TableCell>
                <TableCell align="right">Date</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {events.map((event) => (
                <TableRow hover key={event.eventId}>
                  {repData?.role === 'admin' && (
                    <TableCell>
                      <Link
                        to={`/app/verify-respond?userId=${event.userId}&sid=${event.sid}&eventId=${event.eventId}&uuid=${event.uuid}`}
                      >
                        <IconButton aria-label="verify-respond">
                          <WebIcon />
                        </IconButton>
                      </Link>
                    </TableCell>
                  )}
                  <TableCell>{event.info}</TableCell>
                  <TableCell>
                    <Label color={labelColors[event.pcs_status]}>
                      {mapPcsEventStatus(event)}
                    </Label>
                  </TableCell>
                  <TableCell>{event.alarmState}</TableCell>
                  <TableCell>{event.tracks?.length}</TableCell>
                  <TableCell>{event.moves?.length}</TableCell>
                  <TableCell>{event.faces?.length}</TableCell>
                  <TableCell align="right">
                    {moment.unix(event.eventTimestamp).fromNow()}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <Box p={2} display="flex" justifyContent="flex-end">
        <Button
          component={RouterLink}
          size="small"
          to="/app/event-list"
          endIcon={<NavigateNextIcon />}
        >
          See all
        </Button>
      </Box>
    </Card>
  );
};

EventList.propTypes = {
  className: PropTypes.string,
};

export default EventList;
