import {
  Box,
  FormControlLabel,
  MenuItem,
  Select,
  Switch,
} from '@material-ui/core';
import React, { FC, useState } from 'react';

import {
  useAuthorizeCluster,
  useSetDetectionFlagMutation,
} from '../../../../hooks/eventHooks';
import { ITrack } from '../../types';

interface IProps {
  eventId: number;
  track: ITrack;
  onImportant: (img_file) => void;
  showCluster?: boolean;
}

enum AllowForHoursAmount {
  ONE = '1',
  SIX = '6',
  TWELVE = '12',
}

const Controls: FC<IProps> = ({ eventId, track, onImportant, showCluster }) => {
  const flagMutation = useSetDetectionFlagMutation(eventId);
  const authorizeClusterMutation = useAuthorizeCluster();

  const [rejectedTrack, setRejectedTrack] = useState(!!track.rejected);
  const [importantTrack, setImportantTrack] = useState(!!track.important);
  const [friendlyCluster, setFriendlyCluster] = useState(
    track.friendly_cluster
  );

  const handleRejected = () => {
    flagMutation.mutate({
      category: track.category,
      detectionId: track.id,
      flag: 'rejected',
      state: !rejectedTrack,
    });

    setRejectedTrack(!rejectedTrack);
  };

  const handleImportant = (img_file) => {
    flagMutation.mutate({
      category: track.category,
      detectionId: track.id,
      flag: 'important',
      state: !importantTrack,
    });

    if (!importantTrack && onImportant && typeof onImportant === "function") {
      onImportant(img_file);
    }

    setImportantTrack(!importantTrack);
  };

  const onClusterFriendlySelectHandler = ({ target }) => {
    authorizeClusterMutation.mutate({
      clusterId: track.cluster_id,
      authorized_time: target.value,
    });

    setFriendlyCluster(target.value);
  };

  return (
    <Box p={1}>
      <Box sx={{'filter': 'blur(5px)'}}>
        <FormControlLabel
          label="Rejected"
          control={
            <Switch
              color="secondary"
              size="small"
              checked={rejectedTrack}
              onChange={() => {
                handleRejected();
              }}
            />
          }
        />
      </Box>
      <FormControlLabel
        label="Important"
        control={
          <Switch
            color="primary"
            size="small"
            checked={importantTrack}
            onChange={() => {
              handleImportant(track.img_file);
            }}
          />
        }
      />
      {showCluster && track.cluster_id && (
        <Box sx={{'filter': 'blur(5px)'}}>
          <FormControlLabel
            label="Allow this Person"
            control={
              <Select
                color="primary"
                margin="dense"
                label="Allow this Person"
                onChange={onClusterFriendlySelectHandler}
                value={friendlyCluster || ''}
              >
                <MenuItem value={60 * 60}>1 hour</MenuItem>
                <MenuItem value={60 * 60 * 6}>6 hours</MenuItem>
                <MenuItem value={60 * 60 * 12}>12 hours</MenuItem>
              </Select>
            }
          />
        </Box>
      )}
    </Box>
  );
};

export default Controls;
