import { Box, Card, Grid, makeStyles } from '@material-ui/core';
import DenseCardHeader from 'src/components/DenseCardHeader';
import { useAppTheme } from 'src/context/theme-context';
import { Icon } from '../../constants';
import { ReactElement, SyntheticEvent, useCallback } from 'react';
import { SsCamera } from 'src/types';

const useStyles = makeStyles({
  placeholder: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    backgroundColor: 'black',
    textTransform: 'uppercase',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    textAlign: 'center',
  },
  cameraDefault: {
    height: 'calc(100% - 20px)',
  },
  cameraWrapper: {
    position: 'absolute',
    top: '1.5em', // this is tied to height of text in "dense card header" (or we get rid of header and use top: 0)
    left: '4px',
    width: 'calc(100% - 8px)',
    height: 'calc(67vh - 23px)',
    overflow: 'hidden',
  },
});

type Props = {
  active: boolean;
  camera: SsCamera;
  children: ReactElement;
  onCameraChangeClickHandler: (e: SyntheticEvent, uuid: string) => void;
};

const CameraGridTile = ({
  active,
  camera,
  children,
  onCameraChangeClickHandler,
}: Props) => {
  const { themeName } = useAppTheme();
  const classes = useStyles();

  const showCameraIcon = useCallback(
    (c: SsCamera) => {
      const MODEL = c.model.toUpperCase();

      if (MODEL === 'SSOBCM4') {
        const arr = camera?.cameraSettings?.admin?.battery || [];
        const isAll100 = !arr
          .slice(Math.max(arr.length - 5, 0))
          ?.map((item) => item?.[1])
          ?.find((percentage) => percentage !== 100);

        const shieldDagger = isAll100 && camera?.currentState?.batteryCharging;
        if (shieldDagger) {
          return themeName === 'dark' ? Icon.daggerWhite : Icon.daggerBlack;
        } else {
          return themeName === 'dark' ? Icon.shieldWhite : Icon.shieldBlack;
        }
      }

      if (MODEL === 'SS002') {
        return themeName === 'dark' ? Icon.doorbellWhite : Icon.doorbellBlack;
      }

      if (MODEL === 'SS001' || MODEL === 'SS003') {
        return themeName === 'dark' ? Icon.simplicamWhite : Icon.simplicamBlack;
      }
    },
    [themeName]
  );

  return (
    <Grid
      key={camera.uuid}
      item
      xs={4}
      onClick={(e) => onCameraChangeClickHandler(e, camera.uuid)}
    >
      <Card>
        <Box display="flex" flexDirection="column" className={'fs-exclude'}>
          <DenseCardHeader
            title={camera.cameraSettings.cameraName}
            cameraIcon={showCameraIcon(camera)}
            tooltipData={`FW ${camera.cameraStatus?.firmwareVersion}`}
          />
          {active && (
            <>
              <div className={`${classes.placeholder} aspectRatio`}>
                {camera.cameraSettings.cameraName}
              </div>
              <p
                className={classes.placeholder}
                style={{
                  height: '36px',
                  backgroundColor: '#e7e7e7',
                  color: 'black',
                }}
              >
                Playing in main viewer
              </p>
            </>
          )}
          <div
            className={active ? classes.cameraWrapper : classes.cameraDefault}
          >
            {children}
          </div>
        </Box>
      </Card>
    </Grid>
  );
};

export default CameraGridTile;
