// NOTE: This "player" is currently a test.  It wakes up the camera (with webrtc mediator call) then triggers a recording (via Yoda) then plays that recording (HLS playback)

import React, { useEffect, RefObject } from 'react';
import Hls, { HlsConfig } from 'hls.js';
import { useQuery } from 'react-query';
import { log } from 'src/context/Logs';

type VideoType = {
  clipId: number;
  uuid: string;
  timestamp: number;
  preroll: number;
  postroll: number;
  cameraName: string;
  recordingType: 'KVS' | 'TEP';
  account: string;
  region: string;
};

export interface HlsPlayerProps
  extends React.VideoHTMLAttributes<HTMLVideoElement> {
  hlsConfig?: HlsConfig;
  playerRef: RefObject<HTMLVideoElement>;
  style?: any;
  authHeader: string;
  uuid: string;
  sid: number;
  eventId: number;
  isTalking: boolean;
  retryVideo: number;
  // video: VideoType;
  url: string;
  isLive: boolean;
  onConnectionState: (connectionState: string | null) => void;
}

function HlsPlayer({
  hlsConfig,
  playerRef = React.createRef<HTMLVideoElement>(),
  style,
  authHeader,
  uuid,
  sid,
  eventId,
  isTalking,
  retryVideo,
  onConnectionState,
  // video,
  url,
  isLive = false,
  ...props
}: HlsPlayerProps) {
  /**
  const config = {
    headers: {
      Authorization: authHeader,
    },
  };

  let start = video.timestamp - video.preroll;
  let end = video.timestamp + 60;

  Only Enable Recording if all the fields are present
  const recordingEnabled = !!(
    start &&
    end &&
    uuid &&
    sid &&
    video.region &&
    video.account
  );

  const mediaPlayOptions = {
    enabled: recordingEnabled,
    retry: true,
    refetchOnWindowFocus: false,
  };

  const mediatorPlayQuery = useQuery(
    ['mediator_play_recording', `${uuid}_${eventId}`],
    () =>
      window
        .fetch(
          `${process.env.REACT_APP_SS_MEDIATOR_URL}/v1/recording/${uuid}/${sid}/time/${start}/${end}/hls?account=${video.account}&region=${video.region}`,
          config
        )
        .then((response) => {
          if (!response.ok) {
            return Promise.reject(
              new Error(`Server Failure: ${response.status}`)
            );
          }

          return response.json();
        }),
    mediaPlayOptions
  );

  const effectList = [playerRef, mediatorPlayQuery.data];
 */
  useEffect(() => {
    // if (!mediatorPlayQuery.data) return () => {};

    if (!url) return () => { };
    const src = url;

    // Got a src to play
    onConnectionState(null);

    let hls: Hls;

    function initPlayer() {
      if (hls != null) {
        hls.destroy();
      }

      const newHls = new Hls({
        enableWorker: false,
        ...hlsConfig,
      });

      if (playerRef.current != null) {
        newHls.attachMedia(playerRef.current);
      }

      newHls.on(Hls.Events.MEDIA_ATTACHED, () => {
        try {
          newHls.loadSource(src);
        }
        catch (error) {
          log({
            message: `Failed to load video source.`,
            data: {
              error,
              'URL': src,
            },
          });
        }

      });

      newHls.on(Hls.Events.ERROR, (event, data) => {
        if (data.fatal) {
          switch (data.type) {
            case Hls.ErrorTypes.NETWORK_ERROR:
              newHls.startLoad();
              break;
            case Hls.ErrorTypes.MEDIA_ERROR:
              newHls.recoverMediaError();
              break;
            default:
              initPlayer();
              break;
          }
        }
      });

      hls = newHls;
    }

    // Check for Media Source support
    if (Hls.isSupported()) {
      initPlayer();
    }

    return () => {
      if (hls != null) {
        hls.destroy();
      }
    };
  }, [url]);

  // If Media Source is supported, use HLS.js to play video
  if (Hls.isSupported())
    return (
      <video style={{ ...style }} autoPlay={true} ref={playerRef} {...props} />
    );

  // Fallback to using a regular video player if HLS is supported by default in the user's browser
  // return <video ref={playerRef} src={src} autoPlay={autoPlay} {...props} />;
  return (
    <video style={{ ...style }} autoPlay={true} ref={playerRef} {...props} />
  );
}

export default HlsPlayer;
