import {
  Box,
  Card,
  CardContent,
  FormControlLabel,
  Grid,
  MenuItem,
  Select,
  Switch,
} from '@material-ui/core';
import DenseCardHeader from '../../components/DenseCardHeader';
import React from 'react';
import MonitorStats from './MonitorStats';
import { AlarmState } from './MonitorView';
import { useGetRepMe } from 'src/hooks/repHooks';
import { Role } from 'src/models';

const maxOnScreenDataArray = [
  { id: 1, value: 1, name: 1 },
  { id: 3, value: 3, name: 3 },
  { id: 6, value: 6, name: 6 },
  { id: 9, value: 9, name: 9 },
];

const autoExpireDataArray = [
  { id: 1, value: 30, name: '30 sec' },
  { id: 2, value: 45, name: '45 sec' },
  { id: 3, value: 60, name: '1 min' },
  { id: 4, value: 2 * 60, name: '2 min' },
  { id: 5, value: 3 * 60, name: '3 min' },
  { id: 6, value: 4 * 60, name: '4 min' },
  { id: 7, value: 5 * 60, name: '5 min' },
  { id: 8, value: 10 * 60, name: '10 min' },
  { id: 9, value: 60 * 60, name: '60 min' },
];

const pcsStatusThresholdDataArray = [
  { id: 1, value: -30, name: 'Rep Handled' },
  { id: 2, value: -20, name: 'PCS AI Handled' },
  { id: 3, value: -10, name: 'Expired' },
  { id: 4, value: 0, name: 'New' },
  { id: 5, value: 10, name: 'Watching' },
  { id: 6, value: 20, name: 'Verifying' },
  { id: 7, value: 30, name: 'Dispatching' },
  { id: 8, value: 40, name: 'Follow Up' },
];

const threatLevelDataArray = [
  { id: 1, value: 0, name: 'None' },
  { id: 2, value: 10, name: 'Motion' },
  { id: 3, value: 20, name: 'Person' },
  { id: 4, value: 30, name: 'TBD' },
];

const lookbackDataArray = [
  { id: 1, value: 1 * 60, name: '1 min' },
  { id: 2, value: 5 * 60, name: '5 min' },
  { id: 3, value: 10 * 60, name: '10 min' },
  { id: 4, value: 60 * 60, name: '60 min' },
  { id: 5, value: 120 * 60, name: '2 hr' },
  { id: 6, value: 60 * 60 * 24, name: '1 day' },
  { id: 7, value: 60 * 60 * 24 * 2, name: '2 day' },
  { id: 8, value: 60 * 60 * 24 * 6, name: '6 day' },
];

const renderItemsHelper = (arr) => {
  return arr.map((item) => (
    <MenuItem key={item.id} value={item.value}>
      {item.name}
    </MenuItem>
  ));
};

export const QueueControls = (props) => {
  const {
    acceptNew,
    setAcceptNew,
    classes,
    limit,
    setLimit,
    autoExpire,
    setAutoExpire,
    pcsStatusThreshold,
    setPcsStatusThreshold,
    setArmstate,
    setLookback,
    armstate,
    setThreatLevel,
    threatLevel,
    lookback,
    experimental,
    setExperimental,
  } = props;

  const armstateDataArray = [
    { id: 1, value: AlarmState.ALL, name: 'All' },
    { id: 2, value: AlarmState.UNARMED, name: 'Unarmed' },
    { id: 3, value: AlarmState.ARMED, name: 'Armed' },
  ];

  const repQuery = useGetRepMe();
  const isQueueControlsDisabled = repQuery?.data?.role != Role.ADMIN;

  return (
    <Grid item xs={2}>
      <Box height="10vh" sx={{'filter': 'blur(5px)'}}>
        <Grid container spacing={1} wrap="wrap">
          <Grid item xs={12}>
            <Card>
              <DenseCardHeader title="Queue controls" />
              <CardContent>
                <FormControlLabel
                  control={
                    <Switch
                      checked={acceptNew}
                      onChange={(e) => {
                        setAcceptNew(e.target.checked);
                      }}
                      name="acceptNew"
                    />
                  }
                  label="Accept New"
                />
                <FormControlLabel
                  className={classes.formControl}
                  label="Max Onscreen"
                  control={
                    <Select
                      value={limit}
                      onChange={(e: React.ChangeEvent<{ value: unknown }>) => {
                        setLimit(e.target.value as number);
                      }}
                      disabled={isQueueControlsDisabled}
                    >
                      {renderItemsHelper(maxOnScreenDataArray)}
                    </Select>
                  }
                />
                <FormControlLabel
                  className={classes.formControl}
                  label="Auto Expire"
                  control={
                    <Select
                      value={autoExpire}
                      onChange={(e: React.ChangeEvent<{ value: unknown }>) => {
                        setAutoExpire(e.target.value as number);
                      }}
                      disabled={isQueueControlsDisabled}
                    >
                      {renderItemsHelper(autoExpireDataArray)}
                    </Select>
                  }
                />
                <FormControlLabel
                  className={classes.formControl}
                  label="PCS Status"
                  control={
                    <Select
                      value={pcsStatusThreshold}
                      onChange={(e: React.ChangeEvent<{ value: unknown }>) => {
                        setPcsStatusThreshold(e.target.value as number);
                      }}
                      disabled={isQueueControlsDisabled}
                    >
                      {/* <MenuItem value={-30}>Operator Handled</MenuItem> */
                      /* NOTE: can't really show handled events, since their assign "slot" will overlap with other events handled in that same slot... */}
                      {renderItemsHelper(pcsStatusThresholdDataArray)}
                    </Select>
                  }
                />
                <FormControlLabel
                  className={classes.formControl}
                  label="Threat Level"
                  control={
                    <Select
                      value={threatLevel}
                      onChange={(e: React.ChangeEvent<{ value: unknown }>) => {
                        setThreatLevel(e.target.value as number);
                      }}
                      disabled={isQueueControlsDisabled}
                    >
                      {renderItemsHelper(threatLevelDataArray)}
                    </Select>
                  }
                />
                <FormControlLabel
                  className={classes.formControl}
                  label="Lookback"
                  control={
                    <Select
                      value={lookback}
                      onChange={(e: React.ChangeEvent<{ value: unknown }>) => {
                        setLookback(e.target.value as number);
                      }}
                      disabled={isQueueControlsDisabled}
                    >
                      {renderItemsHelper(lookbackDataArray)}
                    </Select>
                  }
                />
                <FormControlLabel
                  className={classes.formControl}
                  label="Arm State"
                  control={
                    <Select
                      value={armstate}
                      onChange={(e: React.ChangeEvent<{ value: unknown }>) => {
                        setArmstate(e.target.value as AlarmState);
                      }}
                      disabled={isQueueControlsDisabled}
                    >
                      {renderItemsHelper(armstateDataArray)}
                    </Select>
                  }
                />
                <FormControlLabel
                  control={
                    <Switch
                      checked={experimental}
                      onChange={(e) => {
                        setExperimental(e.target.checked);
                      }}
                      name="experimental"
                      disabled={isQueueControlsDisabled}
                    />
                  }
                  label="Use SS Video"
                />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12}>
            {/* <MonitorStats /> */}
            {/* MonitorStats is disabled because it does not display real data */}
            {/* <Typography>Events Handled: {eventsHandled}</Typography> */}
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
};
